import React from "react";

const AIIcon = () => {
  return (
    <div className="ai-icon">
      <img
        src={require(`assets/custom-styles/images/icons/aiIcon.svg`)}
        alt="img-ins"
        height="24"
        width="24"
        fill="white"
      ></img>
    </div>
  )
}

export default AIIcon