import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WorkflowHeader } from "./WorkflowHeader";
import Paper from '@mui/material/Paper';
import * as actions from "../../../redux/actions/WorkflowActions";
import * as llmActions from "../../../redux/actions/LLMActions"
import { checkProject } from "../../../redux/actions/ProjectsActions";
import ProjectLink from "./ProjectLink";
import * as commonService from "utility/CommonService.js";
import CurrentTask from "./CurrentTask";
import TaskDisplay from "./TaskDisplay";
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from "@mui/material/Tooltip";
import {Button} from 'react-bootstrap';
import { AutoAwesomeOutlined } from "@mui/icons-material";
import { trimSpacesAfterNewlineCharacters } from "../../../utility/utility";
import CloseIcon from "@mui/icons-material/Close";
import { ENTITY_TYPES } from "utility/constants/constants";

// call update workflow here and add UI
const WorkflowDetails = ({ id }) => {
  const dispatch = useDispatch();
  const {dagInfo, currentWorkflow, workflowTaskInstanceLogs} = useSelector((state) => state.workflowReducer);
  const {projectPermission} = useSelector((state) => state.projectsReducer);
  const { user } = useSelector((state) => state.authReducer);
  const [workflowRun, setWorkflowRun] = useState('');
  const [workflowTaskInstance, setWorkflowTaskInstance] = useState('')
  const [workflowTaskTryNumber, setWorkflowTaskTryNumber] = useState('')
  const [workflowTaskInstanceObject, setWorkflowTaskInstanceObject] = useState({})
  const summary = useSelector((state) => state.llmReducer.summary)
  const [showSummary, setShowSummary] = useState(false);
  const [summaryClicked,handleSummaryClicked] = useState(false);
  // Regex pattern to filter out redundant logs
  const infoPattern = /\{[^}]+\}\sINFO -/g;
  const markerLine = 'Tmp dir root location:/tmp';
  useEffect(() => {
    const fetchData = async () => {
      const workflowResponse = await dispatch(actions.getWorkflow(id));
      if (workflowResponse.action.payload && workflowResponse.action.payload.success) {
        const project_id = workflowResponse.value.workflow.project_id
        dispatch(checkProject({id: project_id}));
        dispatch(actions.getWorkflowRuns(id));
        dispatch(actions.getLatestTasks({body:{limit:5},id:id}))
        dispatch(actions.getDagInfo(id));
      }
    };
    fetchData();
  }, [dispatch, id]);

  const closeSummary = () => {
    setShowSummary(false);
    dispatch(llmActions.clearLogSummary())
  }

  const handleLogSummary = (logs) => {
    handleSummaryClicked(true);
    setShowSummary(true);
    dispatch(llmActions.createLogSummary(id, {
      logs: logs.split('\n'),
      entity: ENTITY_TYPES.WORKFLOW
    })).then((res) => {
      if (!res?.value?.success) {
        commonService.forError("Could not retrieve log summary.", "Error");
      }
      handleSummaryClicked(false);
    })
  }

  const handleSelectWorkflowRun = (selectedOption) => {
    if (workflowTaskInstance) {
      setWorkflowTaskInstance('')
    }
    const updatedTaskList = {}
    setWorkflowRun(selectedOption);
    dispatch(actions.getWorkflowTaskInstances(id, selectedOption.value)).then((res) => {
      if (res.value && res.value.success) {
        res.value.taskInstances.task_instances.forEach((taskInstance) => {
          updatedTaskList[taskInstance.task_id] = {
            try_number: taskInstance.try_number,
            state: taskInstance.state,
            duration: taskInstance.duration
          };
        });
        setWorkflowTaskInstanceObject(updatedTaskList)
      } else {
        // Did not get task instances
        commonService.forError("Unable to fetch task instances", "Error")
      }
    })
  };

  const handleSelectWorkflowTaskInstance = (selectedOption) => {
    setWorkflowTaskInstance(selectedOption);
    if (workflowTaskTryNumber) {
      setWorkflowTaskTryNumber('')
    }
  };

  const handleSelectWorkflowTaskTryNumber = (selectedOption) => {
    setWorkflowTaskTryNumber(selectedOption);

  };


  const refreshWorkflow = () => {
    dispatch(actions.getWorkflowRuns(id));
    dispatch(actions.getLatestTasks({body:{limit:5},id:id}))
    if (workflowRun && workflowTaskInstance && workflowTaskTryNumber) {
      dispatch(actions.getWorkflowTaskInstanceLogs(id, workflowRun.value, workflowTaskInstance.value, workflowTaskTryNumber.value));
    }
  }

  const clearTask = () => {
    const body = {
      task_ids: [workflowTaskInstance.value],
      dag_run_id: workflowRun.value,
      include_downstream: true,
    }
    dispatch(actions.clearTask({body, id})).then((res) => {
      if (res.value && res.value.success) {
        commonService.forSuccess("Task Successfully Cleared")
        setWorkflowTaskInstance('');
        setWorkflowTaskTryNumber('');
        setWorkflowTaskInstanceObject({});
        setWorkflowRun('')
        dispatch(actions.getWorkflowRuns(id));
        dispatch(actions.getLatestTasks({body:{limit:5},id:id}))
      }
    })
  }

  function formatLogs(logs) {
    let inputString;
    // Remove outer braces
    inputString = logs.slice(3,-3);
    // Split by //n in text which
    inputString = inputString.split('\\n');
    let parsedLog = inputString.map(entry => entry.replace(infoPattern, ''));
    const markerIndex = parsedLog.indexOf(markerLine);
    parsedLog = parsedLog.slice(markerIndex + 1)
    return parsedLog;
  }

  const markSuccess = async (dagId, state, workflowRunValue, task)  => {
    let id = dagId
    dispatch(actions.markWorkflow(
      {
        body: {
          state: state,
          dagRunId: workflowRunValue,
          taskId: task
        },
        id,
      }
    )).then((res) => {
      if (res.value && res.value.success) {
        dispatch(actions.getWorkflows());
        dispatch(actions.getWorkflowRuns(id));
        dispatch(actions.getLatestTasks({body:{limit:5},id:id}))
        commonService.forSuccess("Task update successful!", "Success")
      } else {
        commonService.forError("Workflow update failed", "Error")
      }
    })
  };

  return (
    <>
      <div className="lds_workflow_bottom_box_wrapper">
        {currentWorkflow &&
          <WorkflowHeader
            workflow={currentWorkflow}
            user={user}
            dagInfo={dagInfo}
            projectAccessLevel = {projectPermission?.access_level}
        />}
        <div>
          <div className="container details-container">
            <div className="action-card-container">
              <TaskDisplay
                id={id}
                handleSelectWorkflowRun={handleSelectWorkflowRun}
                handleSelectWorkflowTaskInstance={handleSelectWorkflowTaskInstance}
                handleSelectWorkflowTaskTryNumber={handleSelectWorkflowTaskTryNumber}
                workflowRun={workflowRun}
                workflowTaskInstance={workflowTaskInstance}
                workflowTaskTryNumber={workflowTaskTryNumber}
                workflowTaskInstanceObject={workflowTaskInstanceObject}
                >
              </TaskDisplay>
              <CurrentTask
                accessLevel = {currentWorkflow?.access_level}
                dagId = {dagInfo?.dag_id}
                task = {workflowTaskInstance.value}
                status = {workflowTaskInstanceObject[workflowTaskInstance.value]?.state}
                runTime = {workflowTaskInstanceObject[workflowTaskInstance.value]?parseFloat(workflowTaskInstanceObject[workflowTaskInstance.value].duration):null}
                workflowRun = {workflowRun}
                markSuccess = {markSuccess}
                clearTask = {clearTask}
              ></CurrentTask>
              <ProjectLink
                projectName = {projectPermission?.name}
                lastEditDateTime = {projectPermission?.updated_at}
                accessLevel = {projectPermission?.access_level}
                workflow = {currentWorkflow}
              >
              </ProjectLink>
            </div>
            <div className="logs-container">
              <div className = "logs-title">
                <h4>Logs</h4>
                <Tooltip
                  title='Refresh'
                  placement="top"
                >
                  <div>
                    <Button
                      onClick={() => refreshWorkflow()}
                      className="refresh-btn"
                    >
                      <RefreshIcon/>
                    </Button>
                  </div>
                </Tooltip>
                <div className="container_logs_text">
                  <div className="logs">
                  {
                    showSummary && summary &&
                    <div className="summary_text_box workflows_text_box">
                      <div className="summary_header_row">
                        <div className="summary_header">Summary</div>
                        <CloseIcon
                          onClick={() => closeSummary()}
                        />
                      </div>
                      {trimSpacesAfterNewlineCharacters(summary)}
                    </div>
                  }
                  {workflowTaskInstanceLogs !== "" &&
                    <button className="workflows_summary_button"
                        disabled={summaryClicked === true}
                        onClick={() => handleLogSummary(workflowTaskInstanceLogs)}>
                      <div className="summary_button_container">
                        <AutoAwesomeOutlined></AutoAwesomeOutlined>
                        {
                          summaryClicked ? (
                            <div className="summary_button_text">Loading...</div>) : (
                            <div className="summary_button_text">Summary</div>
                          )
                        }
                      </div>
                    </button>
                  }
                 </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="lds_project_over_box float_left" style={{paddingTop: '20px', width: '150%'}}>
                      {
                        workflowTaskTryNumber !== undefined ? (
                          workflowTaskTryNumber === 0 ? (
                            <div>
                              <p style={{ marginTop: '50px', marginLeft: '400px' }}>No Logs available for this task</p>
                            </div>
                          ) : (
                            <div className="logs-card">
                              <Paper elevation={3} sx={{ padding: '15px', width: '100', marginBottom: '20px' }}>
                                {formatLogs(workflowTaskInstanceLogs).map((log, index) => (
                                  <p key={index}>{log !== undefined ? log : ""}</p>
                                ))}
                              </Paper>
                            </div>
                          )
                        ) : (
                          <div>
                            <p style={{ marginTop: '50px', marginLeft: '400px' }}>Logs will show here</p>
                          </div>
                        )
                      }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default WorkflowDetails;
