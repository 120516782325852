import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import EnvVars from "./EnvVars";
import ContainerLogs from "./ContainerLogs";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import DockerTerminal from "./DockerTerminal";
import TerminalIcon from '@mui/icons-material/Terminal';
import { ENTITY_TYPES } from "../../../utility/constants/constants";
import { ProjectSummaryClickedContext } from "./ProjectIDE";
import ChatInterface from "components/shared/ChatBox";


const DevTools = ({ projectId, handleShowEnvVariables, handleShowLogs, showLogs, showEnvVars, showTerminal, handleShowTerminal, showAIChat, handleShowAIChat, chatRef }) => {
  const { previewContainerInfo } = useSelector((state) => state.appReducer)
  const NAV_WIDTH = 500;
  const NAV_WIDTH_MAX = 550;
  const NAV_WIDTH_MIN = 200;
  const [width, setWidth] = useState(NAV_WIDTH);
  const terminalWrapperRef = useRef();

  // Set up a window of the last 100 logs
  const logWindow = useRef([]);

  const calcuateDevtoolsDivHeight = () => {
    const trueCount = [showLogs, showEnvVars, showTerminal].filter(Boolean).length;
    switch (trueCount) {
      case 1:
        return "100%";
      case 2:
        return '50%';
      case 3:
        return '33.33%'
      default:
        return '100%'
    }
  }

  const onResize = (event, { size }) => {
    setWidth(size.width);
  };

  return (
    <Resizable
      resizeHandles={['w']}
      minConstraints={[NAV_WIDTH_MIN]}
      maxConstraints={[NAV_WIDTH_MAX]}
      resize
      onResize={onResize}
      width={width}
      handle={<div className="border_handle_left"></div>}
    >
      <div className="resize_wrapper">
        <div style={{ width: `${width}px` }} className="dev_windows_wrapper">
          {showEnvVars && (
            <EnvVars
              handleShowEnvVariables={handleShowEnvVariables}
              divHeight={calcuateDevtoolsDivHeight()}
            />
          )}
          {showAIChat && chatRef && (
            <div
            className="chat_interface_wrapper"
            style={{
              visibility: showAIChat ? 'visible' : 'hidden' ,
            }}
          >
            <ChatInterface
              projectId={projectId}
              handleShowAIChat={handleShowAIChat}
              chatRef={chatRef}
            />
          </div>
          )}
          {showLogs && previewContainerInfo.containerId && (
            <div
              className="container_logs_wrapper"
              style={{ height: calcuateDevtoolsDivHeight() }}
            >
              <div className="dev_tool_header">
                <SortIcon />
                <Tooltip title={"Close"}>
                  <CloseIcon
                    className="float-right"
                    onClick={() => handleShowLogs(false)}
                  />
                </Tooltip>
              </div>
              <ContainerLogs summaryClickedContext={ProjectSummaryClickedContext} areLogsVisible={showLogs} entityId={projectId} entityType={ENTITY_TYPES.PROJECT_V2} containerId={previewContainerInfo.containerId} logWindow={logWindow}/>
            </div>
          )}
          {showTerminal && previewContainerInfo.containerId && terminalWrapperRef && (
            <div className="terminal_wrapper" ref={terminalWrapperRef} style={{height: calcuateDevtoolsDivHeight()}}>
              <div className="dev_tool_header">
                <TerminalIcon />
                <Tooltip title={"Close"}>
                  <CloseIcon className="float-right" onClick={handleShowTerminal} />
                </Tooltip>
              </div>
              <DockerTerminal
                containerId={previewContainerInfo.containerId}
                terminalWrapperRef={terminalWrapperRef}
                terminalColor={"#FFFFFF"}
              />
            </div>
          )}
        </div>
      </div>
    </Resizable>
  );
};

export default DevTools;
