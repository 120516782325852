import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import * as commonService from "utility/CommonService.js";
import { ACCESS_LEVEL } from "utility/constants/constants";
import { Tooltip } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  StyledMenu,
  StyledMenuItem,
} from "components/shared/header/components/StyledVisualWorkflowComponents";
import { KeyboardArrowDown } from "@mui/icons-material";
import { TASK_COLORS, TASK_STATE } from "utility/constants/constants";

const CurrentTask = ({
  accessLevel,
  task,
  workflowRun,
  dagId,
  markSuccess,
  runTime,
  status,
  clearTask,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuOpen = Boolean(menuAnchor);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
  };
  const handleMenuClose = (e) => {
    if (e) e.stopPropagation();
    setMenuAnchor(null);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  const openModal = () => {
    if (!task) {
      commonService.forError("Please select a task to re-run");
    } else {
      setShowModal(true);
    }
  };

  const handleSumbit = () => {
    handleClose();
    clearTask();
  };

  let statusColor = "";
  if (status === TASK_STATE[0]) {
    statusColor = TASK_COLORS["SUCCESS"];
  } else if (status === TASK_STATE[1]) {
    statusColor = TASK_COLORS["FAILED"];
  }

  const statusStyle = {
    color: statusColor,
  };

  return (
    <div className="card-container">
      <h4 className="project-card-title">Current Task</h4>
      <div className="project-card">
        <div className="project-card-header">
          <div className="current-task-info">
            <div className="current-task-labels">
              <p>Task Name:</p>
              <p>Status:</p>
              <p>Run ID:</p>
              <p>Run Time:</p>
            </div>
            <div className="current-task-variables">
              <p>{task}</p>
              <p>
                <span style={statusStyle}>{status}</span>
              </p>
              <Tooltip
                title={workflowRun.label}
                placement="top"
              >
                <p className="truncate-text" title={workflowRun.label}>{workflowRun.label}</p>
              </Tooltip>
              {runTime && (
                <p>
                  {runTime > 60
                    ? `${(runTime / 60).toFixed(2)} min`
                    : `${runTime.toFixed(2)} s`}
                </p>
              )}
            </div>
          </div>
        </div>
        <Tooltip
          title={
            accessLevel === ACCESS_LEVEL.VIEW ? "Execute access needed" : ""
          }
          placement="top"
        >
          <div className="project-card-footer">
            <Button
              className="btn_small"
              id="white_btn"
              onClick={() => openModal()}
              disabled={accessLevel === ACCESS_LEVEL.VIEW}
            >
              <RestartAltIcon className="project-card-icon" />
              Re-run Task
            </Button>
            <Button
              className="btn_small"
              id="white_btn"
              onClick={(event) => handleMenuClick(event)}
              disabled={false}
            >
              <KeyboardArrowDown></KeyboardArrowDown>
              Mark State As
            </Button>
          </div>
        </Tooltip>
      </div>
      <StyledMenu
        id="long-menu-button"
        disableScrollLock={true}
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <StyledMenuItem
          onClick={(e) => {
            setMenuAnchor(null);
          }}
          style={{
            opacity: status === TASK_STATE[0]?.5:1,
          }}
          disabled={status === TASK_STATE[0]}
        >
          <ListItemIcon>
            <div
              className="entity_menu_item"
              onClick={() =>
                markSuccess(dagId, "success", workflowRun.value, task)
              }
            >
              <p> Success</p>
            </div>
          </ListItemIcon>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={(e) => {
            setMenuAnchor(null);
          }}
          style={{
            opacity: status === TASK_STATE[1]?.5:1,
          }}
          disabled={status === TASK_STATE[1]}
        >
          <ListItemIcon>
            <div
              className="entity_menu_item"
              onClick={() =>
                markSuccess(dagId, "failed", workflowRun.value, task)
              }
            >
              <p> Failed</p>
            </div>
          </ListItemIcon>
        </StyledMenuItem>
      </StyledMenu>
      <Modal
        id="edit_modal"
        show={showModal}
        onHide={handleClose}
        animation={false}
        className="edit-app-modal"
      >
        <Modal.Header closeButton>
          <h3>Re-run Task</h3>
        </Modal.Header>
        <form data-toggle="validator" onSubmit={handleSumbit}>
          <Modal.Body>
            <div className="col-md-12">
              <div className="clear-task-div">
                <h4 className="clear-task-title">
                  Are you sure you want to clear and re-run {task}
                </h4>
                <p className="clear-task-text">
                  This will clear all downstream tasks as well
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" value="Submit" className="btn float-right">
              Re-run
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default CurrentTask;
